
.project {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  background-color: var(--project__card-bg);
}


.project:active {
  position: relative;
  top:1px;
}

.project__img {
  margin-bottom: 15px;
  border-radius: 10px;
  max-width: 330px;

}

.project__title {
  padding: 15px 20px 25px;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 700;
  color: var(--text-color)
}

.project-details {
  margin: 0 auto;
  max-width: 865px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.project_details_cover {
  max-width: 100%;
  filter: drop-shadow(var(--box-shadow));
  border-radius: 10px;

  border-radius: 10px;
  margin-bottom: 40px;
}

.project_details_desk {
  margin-bottom: 30px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;

}

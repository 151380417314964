
.nav {
  padding: 20px 0;
  background-color: var(--dark_bg);
  border-bottom: 1px solid var(--dark_border);
  letter-spacing: normal;
}

.nav-row {
  display: flex;
  justify-content: flex-end;
  column-gap: 40px;
  row-gap: 20px;
  flex-wrap: wrap;
}

.logo {
  margin-right: auto;
  color: white;
  font-size: 24px;
  font-family:'Poppins', sans-serif;
}

.logo strong {
  font-weight: 700;
}

.nav-list {
  display: flex;
  align-items: center;
  column-gap: 40px;
  font-size: 16px;
  font-weight: 500;
  font-family:'Poppins', sans-serif;
}

.nav-list__link {
  color: white;
  transition: opacity 0.2s ease-in;
}

.nav-list__link:hover {
  opacity: 0.8;
}

.nav-list__link--active {
  position: relative;
}

.nav-list__link--active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  display: block;
  width: 100%;
  background-color: var(--accent);
  height: 2px;;
}

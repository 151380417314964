.dark-mode-btn {
  order: 9;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  width: 51px;
  height: 26px;
  border-radius: 50px;
  background-color: #272727;
  position: relative;
}

.dark-mode-btn::before {
  content: "";

  position: absolute;
  top:1px;
  left:1px;

  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;

  transition: left 0.2s ease-in;

}

.dark-mode-btn--active::before {
  left: 26px;
}

.dark-mode-btn_icon {
  position: relative;
  z-index: 9;
}

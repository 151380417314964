.header {
  padding: 40px 0;
  min-height: 695px;
  background-color: var(--dark_bg);
  background-image: url("./../../img/abstract-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.header__wrapper {
  padding: 0 15px;
  max-width: 660px;
}

.header__title {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 20px;
}

.header__title strong {
  font-size: 60px;
  font-weight: 700;
}

.header__title em{
  font-style: normal;
  color: var(--accent)
}
.header__text {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 1.333;
}

.header__text p + p{
  margin-top: 0.5em;
}

.btn {
  display: inline-block;
  height: 48px;
  padding: 12px 28px;
  background-color: var(--accent);
  color: white;
  border-radius: 5px;
  letter-spacing: 0.15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.333;
  transition: opacity 0.2s ease-in;
}

.btn:hover {
  opacity: 0.8;
}

.btn:active {
  position: relative;
  top:1px;
}

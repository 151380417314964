.footer {
  background-color: var(--dark_bg);
  padding: 60px 0 50px;
  margin-top: auto;

}

.footer_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 27px;
}

.social_icons {
  display: flex;
  column-gap: 30px;
  row-gap: 30px;
  justify-content: center;
  align-items: center;
}

.icon{
  width: 38px;
}

.copyright {
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
